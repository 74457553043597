<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="isLoading">
    <!-- Component Body -->
    <div class="mb-5">
      <div class="mb-1">Controle de Questões dos Sub-Evento(s):</div>
      <div v-for="(sub, index) in internalOptions" :key="index">
        <hr v-if="index != 0" />
        <b-row align-v="center">
          <b-col md="6">
            <v-select v-model="sub.sub_event" :options="[]" autocomplete="off" :clearable="false" :disabled="true" />
          </b-col>
        </b-row>
        <b-row align-v="center" class="mt-2">
          <b-col md="3">
            <b-form-group label="Número de questões">
              <b-form-input v-model="sub.number_of_questions" type="number" min="0" placeholder="Número de questões" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Questões Reaproveitadas">
              <b-form-input v-model="sub.reused_questions" type="number" min="0" placeholder="Questões Reaproveitadas" />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <p class="text-center aling-bottom pt-3" v-if="internalOptions.length == 0">
        <feather-icon class="mb-25 mr-25" size="20" icon="CoffeeIcon" />
        Não há nenhum sub-evento disponível para seleção
      </p>
    </div>
    <div class="c-footer d-block">
      <b-row>
        <b-col md="4">
          <b-button :disabled="loading" :class="{ 'px-3': loading }" variant="info" @click="onClickSubmit">
            <b-spinner v-if="loading" small />
            <span v-else>Próximo</span>
          </b-button>
        </b-col>
        <b-col md="4">
          Total de questões: <b>{{ internalOptions.reduce((acc, cur) => acc + parseInt(cur.number_of_questions), 0) }}</b>
        </b-col>
        <b-col md="4">
          Total de Questões Reaproveitadas: <b>{{ internalOptions.reduce((acc, cur) => acc +
            parseInt(cur.reused_questions), 0) }}</b>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import appConfig from "@core/app-config/useAppConfig";
import { BSpinner, BFormCheckbox } from "bootstrap-vue";

export default {
  setup() {
    const { skin } = appConfig();
    return {
      skin,
    };
  },
  components: {
    BSpinner,
    BFormCheckbox,
  },
  data() {
    return {
      isLoading: false,
      internalOptions: [],
      expectedPayloads: ["sub_events_questions_numbers"],
      expectedOptions: ["sub_events"],
      inputsEnabled: [],
    };
  },
  props: {
    loading: Boolean,
    rescheduling: Boolean,
    payload: Object,
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.startComponent();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleNextStepHotKey);
  },
  methods: {
    startComponent() {
      // verifica se foi possível lidar com o payload e as options
      if (this.checkOptions() && this.checkPayload()) {
        this.handleOptions();
      } else {
        this.emitError();
      }
      document.addEventListener("keyup", this.handleNextStepHotKey);
    },
    checkPayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        // payload é opcional        
        return this.expectedPayloads.every((key) => key in this.payload);
      }
      return true;
    },
    checkOptions() {
      return (
        this.$props.hasOwnProperty("options") &&
        this.options &&
        this.expectedOptions.every((key) => key in this.options) &&
        Array.isArray(this.options.sub_events) &&
        this.options.sub_events.length > 0 &&
        this.options.sub_events.every(
          (t) => t.hasOwnProperty("value") && t.hasOwnProperty("label") && t.hasOwnProperty("used")
        ) &&
        this.options.hasOwnProperty("can_change")
      );
    },
    handleOptions() {
      this.internalOptions = this.options.sub_events.map((m) => {
        let _sub = { number_of_questions: 0, reused_questions: 0 };
        if (this.payload && this.payload.sub_events_questions_numbers) {
          _sub = this.payload.sub_events_questions_numbers.find(f => f.sub_event_id == m.value);
        }
        return {
          number_of_questions: _sub ? _sub.number_of_questions : 0,
          reused_questions: _sub ? _sub.reused_questions : 0,
          sub_event: m,
        };
      });
    },
    handleResponse() {
      return {
        sub_events_questions_numbers: this.internalOptions.map((m) => {
          return {
            sub_event_id: m.sub_event.value,
            number_of_questions: m.number_of_questions,
            reused_questions: m.reused_questions,
          };
        }),
      };
    },
    onClickSubmit() {
      if (!this.validateQuestions()) {
        this.$emit("warning", {
          type: "info",
          message: "O número de questões deve ser maior que zero e o número de questões reaproveitadas deve ser menor ou igual ao número de questões.",
        });
        return;
      }

      const payload = this.handleResponse();
      payload && this.$emit("submit", payload);
    },
    emitError() {
      this.$emit("error");
    },
    inputEnable(input) {
      return this.rescheduling
        ? this.inputsEnabled.some((s) => s == input)
        : true;
    },
    handleNextStepHotKey(event) {
      if (event.key.toLowerCase() === "p" && event.shiftKey && event.altKey) {
        this.onClickSubmit();
      }
    },
    validateQuestions() {      
      return this.internalOptions.every((m) => parseInt(m.number_of_questions) > 0 && parseInt(m.reused_questions) <= parseInt(m.number_of_questions));
    },
  },
};
</script>

<style scoped>
.c-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
</style>