<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="isLoading">
    <!-- Component Body -->
    <div class="mb-5">
      <b-row v-if="!home_office">
        <b-col md="6">
          <b-form-group label="Escolha um estúdio:">
            <v-select
              v-model="selectedStudio"
              :options="studios"
              :clearable="false"
              autocomplete="off"
              :filterable="false"
              @search="
                (res) => {
                  getAutoCompleteStudios(res);
                }
              "
              :loading="isLoadingStudios"
              :disabled="!inputEnable(expectedPayloads[0])"
            >
              <template v-slot:option="option">
                <div class="mb-0 text-uppercase">
                  {{ option.label }}
                </div>
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Pesquisando... <em>{{ search }}</em>
                </template>
                <em style="opacity: 0.5" v-else>Digite o nome ou local do Estúdio...</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="options.available_for_home_office">
        <b-col>
          <div class="d-flex mt-1">
            <b-form-checkbox
              class="custom-control-success"
              name="check-button"
              v-model="home_office"
              switch
              :disabled="!inputEnable(expectedPayloads[1])"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <strong>Apenas Estúdio HomeOffice ?</strong>
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="selectedStudio" class="mt-2">
        <b-col md="12">
          Estúdio selecionado:
          <div class="d-flex align-items-center mt-50" :style="{ gap: '10px' }">
            <b-button
              variant="outline-danger"
              @click="removeStudio()"
              size="sm"
            >
              <feather-icon icon="XIcon" />
              <span>Remover</span>
            </b-button>
            <div>
              <h5 class="mb-0">{{ selectedStudio.label }}</h5>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="c-footer d-block">
      <b-button
        :disabled="loading"
        :class="{ 'px-3': loading }"
        variant="info"
        @click="onClickSubmit"
      >
        <b-spinner v-if="loading" small />
        <span v-else>Próximo</span>
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import { _newAppointmentService } from "@/services/appointment-service-v2";
import appConfig from "@core/app-config/useAppConfig";
import { BSpinner } from "bootstrap-vue";

export default {
  setup() {
    const { skin } = appConfig();
    return {
      skin,
    };
  },
  components: {
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      isLoadingStudios: false,
      autoCompleteTimer: null,
      queryStudio: "",
      studios: [],
      home_office: false,
      selectedStudio: null,
      expectedPayloads: ["selected_studio", "home_office"],
      expectedOptions: ["available_for_home_office"],
      inputsEnabled: []
    };
  },
  props: {
    loading: Boolean,
    rescheduling: Boolean,
    payload: Object,
    orchestratorId: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.startComponent();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleNextStepHotKey);
  },
  watch: {
    home_office(home_office) {
      if (home_office == true) {
        this.removeStudio();
      }
    },
  },
  methods: {
    startComponent() {
      if (this.checkOptions() && this.checkPayload()) {
        this.handleOptions();
        this.handlePayload();
      } else {
        this.emitError();
      }
      document.addEventListener("keyup", this.handleNextStepHotKey);
    },
    checkPayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        // payload é opcional
        return this.expectedPayloads.every((key) => key in this.payload);
      }
      return true;
    },
    checkOptions() {
      return (
        this.$props.hasOwnProperty("orchestratorId") &&
        this.$props.hasOwnProperty("options") &&
        this.options &&
        this.expectedOptions.every((key) => key in this.options) &&
        this.options.hasOwnProperty("can_change")
      );
    },
    handlePayload() {
      if (this.payload && this.payload.home_office)
        this.home_office = this.payload.home_office;
    },
    handleOptions() {
      this.inputsEnabled = this.options.can_change;
      this.selectedStudio = this.options.selected_studio
        ? this.options.selected_studio
        : null;
    },
    handleResponse() {
      if (!this.options.available_for_home_office) {
        if (this.home_office) {
          this.$emit("warning", {
            type: "info",
            message: "Home Office indisponível",
          });
          return;
        }
      } else {
        if (this.home_office && this.selectedStudio !== null) {
          this.$emit("warning", {
            type: "info",
            message: "Você deve remover o estúdio selecionado",
          });
          return;
        }
      }
      return {
        selected_studio: this.selectedStudio ? this.selectedStudio.value : null,
        home_office: this.home_office,
      };
    },
    onClickSubmit() {
      const payload = this.handleResponse();
      payload && this.$emit("submit", payload);
    },
    emitError() {
      this.$emit("error");
    },
    getAutoCompleteStudios(search) {
      if (search && search.length >= 3) {
        clearTimeout(this.autoCompleteTimer);
        this.isLoadingStudios = true;
        this.autoCompleteTimer = setTimeout(() => {
          _newAppointmentService
            .getStudios(this.orchestratorId, search)
            .then((res) => {
              this.studios = res;
            })
            .catch((error) => {
              this.$emit("warning", {
                type: "danger",
                message:
                  "Ocorreu um erro durante a listagem de estúdios. Por favor, entre em contato com o time de suporte.",
              });
              console.log(error);
            })
            .finally(() => (this.isLoadingStudios = false));
        }, 1500);
      }
    },
    removeStudio() {
      this.selectedStudio = null;
    },
    inputEnable(input) {
      return this.rescheduling ? this.inputsEnabled.some((s) => s == input) : true;
    },
    handleNextStepHotKey(event) {
      if (event.key.toLowerCase() === "p" && event.shiftKey && event.altKey) {
        this.onClickSubmit();
      }
    },
  },
};
</script>
<style scoped>
.c-footer {
  position: absolute;
  bottom: 0px;
}
</style>