<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="isLoading">
    <b-form-group class="mb-5" label="Escolha um tipo de agendamento:">
      <b-button-group v-if="checkOptions">
        <b-button
          v-for="(type, index) in options.types"
          :key="index"
          :variant="selectedTypeColor(type)"
          :disabled="!inputEnable(expectedPayloads[0], type)"
          @click="changeOption(type)"
          class="options"
        >
          {{ type.label }}
        </b-button>
      </b-button-group>
    </b-form-group>
    <div class="c-footer d-block">
      <b-button
        :disabled="loading"
        :class="{ 'px-3': loading }"
        variant="info"
        @click="onClickSubmit"
      >
        <b-spinner v-if="loading" small />
        <span v-else>Próximo</span>
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import appConfig from "@core/app-config/useAppConfig";
import { BButtonGroup, BButton, BFormGroup, BSpinner } from "bootstrap-vue";

export default {
  setup() {
    const { skin } = appConfig();
    return {
      skin,
    };
  },
  components: {
    BButtonGroup,
    BButton,
    BFormGroup,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      type: { value: "lesson", label: "Aula" },
      expectedPayloads: ["selected_type"],
      expectedOptions: ["types"],
      inputsEnabled: [],
    };
  },
  props: {
    loading: Boolean,
    rescheduling: Boolean,
    payload: Object,
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.startComponent();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleNextStepHotKey);
  },
  methods: {
    startComponent() {
      // verifica se foi possível lidar com o payload e as options
      if (this.checkOptions() && this.checkPayload()) {
        this.handleOptions();
        this.handlePayload();
      } else {
        this.emitError();
      }
      document.addEventListener("keyup", this.handleNextStepHotKey);
    },
    checkPayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        // payload é opcional
        return this.expectedPayloads.every((key) => key in this.payload);
      }
      return true;
    },
    checkOptions() {
      return (
        this.$props.hasOwnProperty("options") &&
        this.options &&
        this.expectedOptions.every((key) => key in this.options) &&
        Array.isArray(this.options.types) &&
        this.options.types.length > 0 &&
        this.options.types.every(
          (t) => t.hasOwnProperty("value") && t.hasOwnProperty("label")
        ) &&
        this.options.hasOwnProperty("can_change")
      );
    },
    handlePayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        this.type = this.options.types.find(
          (f) =>
            f.value.toLowerCase() === this.payload.selected_type.toLowerCase()
        );
      }
    },
    handleOptions() {
      this.inputsEnabled = this.options.can_change;
    },
    selectedTypeColor(type) {
      return type.value.toLowerCase() === this.type.value.toLowerCase()
        ? "info"
        : "outline-info";
    },
    changeOption(type) {
      this.type = type;
    },
    handleResponse() {
      return {
        selected_type: this.type.value,
      };
    },
    onClickSubmit() {
      this.$emit("submit", this.handleResponse());
    },
    emitError() {
      this.$emit("error");
    },
    inputEnable(input, type) {
      if (type.value.toLowerCase() === 'events' && process.env.VUE_APP_SCHEDULING_EVENT === "false") return false; // retirar essa linha quando implementar evento
      return this.rescheduling ? this.inputsEnabled.some((s) => s == input) : true;
    },
    handleNextStepHotKey(event) {
      if (event.key.toLowerCase() === "p" && event.shiftKey && event.altKey) {
        this.onClickSubmit();
      }
    },
  },
};
</script>

<style scoped>
.options {
  min-width: 100%;
}
.c-footer {
  position: absolute;
  bottom: 0px;
}
</style>