<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="isLoading">
    <!-- Component Body -->
    <div class="mb-5">
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-calendar
              v-model="date"
              locale="pt-BR"
              :min="new Date()"
              :max="null"
              hide-header
              block
              today-variant="info"
              selected-variant="success"
            />
            <b-form-input
              type="time"
              min="00:00"
              max="23:59"
              step="900"
              v-model="time"
              class="mt-50"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="c-footer d-block">
      <b-button
        :disabled="loading"
        :class="{ 'px-3': loading }"
        variant="info"
        @click="onClickSubmit"
      >
        <b-spinner v-if="loading" small />
        <span v-else>Próximo</span>
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import appConfig from "@core/app-config/useAppConfig";
import { TabContent } from "vue-form-wizard";
import { BCalendar, BSpinner } from "bootstrap-vue";
import moment from "moment";

export default {
  setup() {
    const { skin } = appConfig();
    return {
      skin,
    };
  },
  data() {
    return {
      isLoading: false,
      date: null,
      time: null,
      hourValid: false,
      expectedPayloads: ["date_time"],
    };
  },
  components: {
    BCalendar,
    TabContent,
    BSpinner,
  },
  props: {
    loading: Boolean,
    payload: Object,
  },
  mounted() {
    this.startComponent();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleNextStepHotKey);
  },
  methods: {
    startComponent() {
      const isValid = this.checkPayload();
      if (isValid) {
        this.handlePayload();
      }
      document.addEventListener("keyup", this.handleNextStepHotKey);
    },
    checkPayload() {
      if (this.$props.hasOwnProperty("payload")) {
        if (this.payload && this.payload.date_time) {
          return true;
        }
      }
      return false;
    },
    handlePayload(payload = this.payload) {
      if (payload.date_time) {
        const [date, time] = payload.date_time.split("T");
        this.date = date;
        this.time = time.slice(0, 5);
      }
    },
    handleResponse() {
      if (!this.date) {
        this.$emit("warning", {
          type: "info",
          message: "Você deve informar uma data",
        });
        return;
      }
      if (!this.time) {
        this.$emit("warning", {
          type: "info",
          message: "Você deve informar um horário",
        });
        return;
      }
      this.isValidTime();
      if (!this.hourValid) {
        this.$emit("warning", {
          type: "info",
          message: "A data/hora precisa estar no futuro e ser um valor válido",
        });
        return;
      }
      return {
        date_time: `${this.date}T${this.time}`,
      };
    },
    onClickSubmit() {
      const payload = this.handleResponse();
      payload && this.$emit("submit", payload);
    },
    emitError() {
      this.$emit("error");
    },
    isValidTime() {
      var _date = new Date();
      var _hora = _date.getHours();
      var _minutes = _date.getMinutes();
      const dateFormat = moment(_date).format("YYYY-MM-DD");

      this.hourValid = true;
      if (moment(this.date).isSame(dateFormat)) {
        var minTime = moment(`${_hora}:${_minutes}`, "hh:mm");
        var maxTime = moment("23:59", "hh:mm");
        this.hourValid = moment(this.time, "hh:mm").isBetween(minTime, maxTime);
      }
    },
    handleNextStepHotKey(event) {
      if (event.key.toLowerCase() === "p" && event.shiftKey && event.altKey) {
        this.onClickSubmit();
      }
    },
  },
};
</script>

<style scoped>
.c-footer {
  position: absolute;
  bottom: 0px;
}
</style>