import dateAndHour from "../steps/DateTime.vue";
import projects from "../steps/Project.vue";
import projectEvent from "../steps/ProjectEvent.vue";
import projectLesson from "../steps/ProjectLesson.vue";
import jobType from "../steps/JobType.vue"
import typeComponent from "../steps/Type.vue"
import studio from "../steps/MainStudio.vue";
import transmission from "../steps/Transmission.vue";
import details from "../steps/ScheduleDetails.vue";
import products from "../steps/Product.vue";
import subEventOrder from "../steps/SubEventOrder.vue";
import recordings from "../steps/Recordings.vue";
import supportStudios from "../steps/SupportStudios.vue";
import recurrency from "../steps/Recurrence.vue";
import questionsNumber from "../steps/QuestionsNumber.vue";
import classification from '../steps/Classification.vue'

const steps = {
    "type": {
        component: typeComponent,
        name: "Tipo de Agendamento",
        icon: "SettingsIcon",
        summary: (p, o) => {
            return o && o.types && `${o.types.find(f => f.value.toLowerCase() === p.selected_type.toLowerCase())?.label}`;
        }
    },
    "date-time": {
        component: dateAndHour,
        name: "Data e hora",
        icon: "ClockIcon",
        summary: (p, _) => {
            if (p && p.date_time) {
                const [date, time] = p.date_time.split("T")
                return p && p.date_time && `
                    ${date.split("-").reverse().join("/")} às ${time.slice(0,5).replace(':', 'h')}
                `;
            }
        }
    },
    "job-type": {
        component: jobType,
        name: "Demanda",
        icon: "ClipboardIcon",
        summary: (p, o) => {
            return p && p.selected_job_type && o && o.job_types && `${o.job_types.find(f => f.value === p.selected_job_type)?.label}`;
        }
    },
    "project": {
        component: projects,
        name: "Projeto",
        icon: "ArchiveIcon",
        summary: (_, o) => {
            return o && o.selected_project && `${o.selected_project?.label} [${o.selected_project?.details?.company_name}]`;
        }
    },
    "project-event": {
        component: projectEvent,
        name: "Evento",
        icon: "CalendarIcon",
        summary: (p, o) => {
            if (!o || !o.events || !p || !p.selected_event) return;

            const event = o.events.find((f) => f.value == p.selected_event);
            if (!event) return;

            const recurrence = event.details && event.details.recurrence ? " [Recorrente]" : "";

            return `${o.events.find(f => String(f.value) === String(p.selected_event))?.label}${recurrence}`;
        }
    },
    "project-lesson": {
        component: projectLesson,
        name: "Aula",
        icon: "BookOpenIcon",
        summary: (p, o) => {
            return o && o.lessons && p && p.selected_lesson && `${o.lessons.find(f => String(f.value) === String(p.selected_lesson))?.label}`;
        }
    },
    "sub-event-order": {
        component: subEventOrder,
        name: "Sub Eventos Ordenação",
        icon: "SlidersIcon",
        summary: (p, o) => {
            return 'Ordenação';
        }
    },
    "questions-number": {
        component: questionsNumber,
        name: "Controle de Questões",
        icon: "TargetIcon",
        summary: (p, _) => {
            if (!p || !p.sub_events_questions_numbers) return;

            const totalQuestions = p.sub_events_questions_numbers.reduce((acc, curr) => acc + curr.number_of_questions, 0);

            return `${totalQuestions} ${totalQuestions > 1 ? "questões" : "questão"}`;
        }
    },
    "main-studio": {
        component: studio,
        name: "Estúdio Principal",
        icon: "HeadphonesIcon",
        summary: (p, o) => {
            return p && p.home_office ? "Apenas Estúdio HomeOffice" : o && o.selected_studio && `${o.selected_studio?.label}`;
        }
    },
    "recurrence": {
        component: recurrency,
        name: "Recorrência",
        icon: "RefreshCwIcon",
        summary: (p, o) => {
            const invalidedRecurrences = o?.enabled_recurrences?.length ?? 0;
            if (invalidedRecurrences > 0) {
                return `${invalidedRecurrences} recorrência(s) inválida(s)`;
            }
            return p && p.recurrences && `${p.recurrences.length} recorrência(s)`;
        }
    },
    "support-studios": {
        component: supportStudios,
        name: "Estúdios de Apoio",
        icon: "StarIcon",
        summary: (p, _) => {
            return p && p.sub_events &&`${p.sub_events.length} Apoio(s)`;
        }
    },
    "transmission": {
        component: transmission,
        name: "Transmissões",
        icon: "LinkIcon",
        summary: (p, _) => {
            return p && p.transmissions && `${p.transmissions.length} link(s)`;
        }
    },
    "recordings": {
        component: recordings,
        name: "Gravações",
        icon: "PlayIcon",
        summary: (p, _) => {
            return p && p.selected_sub_events && `${p.selected_sub_events.length} Sub-Evento(s) com pendência de material`;
        }
    },
    "product": {
        component: products,
        name: "Produtos",
        icon: "FlagIcon",
        summary: (p, o) => {
            return p && p.selected_products && o && o.products && `${p.selected_products.map(a => o.products?.find(o => o?.value == a)?.label).join(", ")}`;
        }
    },
    "schedule-details": {
        component: details,
        name: "Detalhes",
        icon: "ThumbsUpIcon",
        summary: (p, _) => {
            return p && p.appointment_name && `${p.appointment_name}`;
        }
    },
    "classification": {
        component: classification,
        name: "Classificações",
        icon: "ListIcon",
        summary: (p, o) => {
            return null
        }
    },
}

export default steps;