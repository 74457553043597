<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="isLoading">
    <!-- Component Body -->
    <div class="mb-5">
      <div class="mb-1">Sub-Evento(s) com pendência de material:</div>
      <div v-for="(sub, index) in internalOptions" :key="index">
        <hr v-if="index != 0" />
        <b-row align-v="center">
          <b-col cols="auto">
            <b-form-checkbox
              v-model="sub.active"
              class="custom-control-warning"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-col>
          <b-col md="5">
            <v-select
              v-model="sub.sub_event"
              :options="[]"
              autocomplete="off"
              :clearable="false"
              :disabled="true"
            />
          </b-col>
        </b-row>
      </div>
      <p class="text-center aling-bottom pt-3" v-if="internalOptions.length == 0">
        <feather-icon class="mb-25 mr-25" size="20" icon="CoffeeIcon" />
        Não há nenhum sub-evento disponível para seleção
      </p>
    </div>
    <div class="c-footer d-block">
      <b-button
        :disabled="loading"
        :class="{ 'px-3': loading }"
        variant="info"
        @click="onClickSubmit"
      >
        <b-spinner v-if="loading" small />
        <span v-else>Próximo</span>
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import appConfig from "@core/app-config/useAppConfig";
import { BSpinner, BFormCheckbox } from "bootstrap-vue";

export default {
  setup() {
    const { skin } = appConfig();
    return {
      skin,
    };
  },
  components: {
    BSpinner,
    BFormCheckbox,
  },
  data() {
    return {
      isLoading: false,
      internalOptions: [],
      expectedPayloads: ["selected_sub_events"],
      expectedOptions: ["sub_events"],
      inputsEnabled: [],
    };
  },
  props: {
    loading: Boolean,
    rescheduling: Boolean,
    payload: Object,
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.startComponent();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleNextStepHotKey);
  },
  methods: {
    startComponent() {
      // verifica se foi possível lidar com o payload e as options
      if (this.checkOptions() && this.checkPayload()) {
        this.handleOptions();
      } else {
        this.emitError();
      }
      document.addEventListener("keyup", this.handleNextStepHotKey);
    },
    checkPayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        // payload é opcional
        return this.expectedPayloads.every((key) => key in this.payload);
      }
      return true;
    },
    checkOptions() {
      return (
        this.$props.hasOwnProperty("options") &&
        this.options &&
        this.expectedOptions.every((key) => key in this.options) &&
        Array.isArray(this.options.sub_events) &&
        this.options.sub_events.length > 0 &&
        this.options.sub_events.every(
          (t) => t.hasOwnProperty("value") && t.hasOwnProperty("label") && t.hasOwnProperty("used")
        ) &&
        this.options.hasOwnProperty("can_change")
      );
    },
    handleOptions() {
      this.internalOptions = this.options.sub_events.filter((f) => !f.used).map((m) => {
        return {
          active:
            this.$props.hasOwnProperty("payload") && this.payload
              ? this.payload.selected_sub_events.some((s) => s == m.value)
              : false,
          sub_event: m,
        };
      });
    },
    handleResponse() {
      const activeSubEvents = this.internalOptions.filter((f) => f.active);
      return {
        selected_sub_events: activeSubEvents.map((m) => m.sub_event.value),
      };
    },
    onClickSubmit() {
      const payload = this.handleResponse();
      payload && this.$emit("submit", payload);
    },
    emitError() {
      this.$emit("error");
    },
    inputEnable(input) {
      return this.rescheduling
        ? this.inputsEnabled.some((s) => s == input)
        : true;
    },
    handleNextStepHotKey(event) {
      if (event.key.toLowerCase() === "p" && event.shiftKey && event.altKey) {
        this.onClickSubmit();
      }
    },
  },
};
</script>

<style scoped>
.c-footer {
  position: absolute;
  bottom: 0px;
}
</style>