<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="isLoading">
    <!-- Component Body -->
    <div class="mb-5">
      <div class="mb-1">Links de Trasmissão:</div>
      <div v-for="(ll, index) in links" :key="ll.id">
        <hr v-if="index != 0" />
        <b-row align-v="center">
          <b-col cols="auto">
            <b-form-checkbox
              v-model="ll.active"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-row>
              <b-col class="my-50" md="4">
                <v-select
                  v-model="ll.transmission_type"
                  :options="options.transmission_types"
                  autocomplete="off"
                  :clearable="false"
                  placeholder="Tipo de Transmissão"
                  :disabled="true"
                />
              </b-col>
              <b-col class="my-50" md="5">
                <div>
                  <v-select
                    v-if="options.companies.length > 0"
                    v-model="ll.company"
                    :options="options.companies"
                    autocomplete="off"
                    :clearable="false"
                    placeholder="Empresas"
                    class="custom-v-select"
                    :disabled="!ll.active || !inputEnable('company_id')"
                  />
                </div>
              </b-col>
              <b-col class="my-50" md="3">
                <v-select
                  v-if="options.visibilities.length > 0"
                  v-model="ll.visibility"
                  :options="options.visibilities"
                  autocomplete="off"
                  :clearable="false"
                  placeholder="Status"
                  :disabled="!ll.active || !inputEnable('visibility')"
                />
              </b-col>
              <b-col class="my-50" md="6">
                <b-form-input
                  placeholder="Informe a URL"
                  autocomplete="off"
                  v-model="ll.url"
                  :disabled="!ll.active || !inputEnable('url')"
                />
              </b-col>
              <b-col class="my-50" md="6">
                <b-form-input
                  placeholder="Título"
                  autocomplete="off"
                  v-model="ll.title"
                  :disabled="!ll.active || !inputEnable('title')"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="c-footer d-block">
      <b-button
        :disabled="loading"
        :class="{ 'px-3': loading }"
        variant="info"
        @click="onClickSubmit"
      >
        <b-spinner v-if="loading" small />
        <span v-else>Próximo</span>
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import appConfig from "@core/app-config/useAppConfig";
import { BSpinner, BFormCheckbox } from "bootstrap-vue";

export default {
  setup() {
    const { skin } = appConfig();
    return {
      skin,
    };
  },
  components: {
    BSpinner,
    BFormCheckbox
  },
  data() {
    return {
      isLoading: false,
      transmissions: [],
      links: [],
      expectedPayloads: ["transmissions"],
      expectedOptions: ["transmission_types", "companies", "visibilities"],
      inputsEnabled: [],
      initial_links: [],
    };
  },
  props: {
    loading: Boolean,
    rescheduling: Boolean,
    payload: Object,
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.startComponent();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleNextStepHotKey);
  },
  methods: {
    startComponent() {
      // verifica se foi possível lidar com o payload e as options
      if (this.checkOptions() && this.checkPayload()) {
        this.handleOptions();
      } else {
        this.emitError();
      }
      document.addEventListener("keyup", this.handleNextStepHotKey);
    },
    checkPayload() {
      const has_correct_options =
        this.$props.hasOwnProperty("payload") &&
        this.payload &&
        this.expectedPayloads.every((key) => key in this.payload);
      if (has_correct_options) {
        const has_correct_options_values = this.expectedPayloads.every(
          (key) => {
            const has_options =
              Array.isArray(this.payload[key]) && this.payload[key].length > 0;
            if (has_options) {
              return this.payload[key].every(
                (t) =>
                  t.hasOwnProperty("transmission_type_id") &&
                  t.hasOwnProperty("company_id") &&
                  t.hasOwnProperty("url") &&
                  t.hasOwnProperty("title") &&
                  t.hasOwnProperty("visibility")
              );
            } else {
              return true;
            }
          }
        );
        return has_correct_options && has_correct_options_values;
      }
      return true;
    },
    checkOptions() {
      const has_correct_options =
        this.$props.hasOwnProperty("options") &&
        this.options &&
        this.expectedOptions.every((key) => key in this.options) &&
        this.options.hasOwnProperty("can_change");
      const has_correct_options_values = this.expectedOptions.every((key) => {
        let validate =
          Array.isArray(this.options[key]) &&
          this.options[key].length > 0 &&
          this.options[key].every(
            (t) => t.hasOwnProperty("value") && t.hasOwnProperty("label")
          );
        if (key === "transmission_types") {
          validate =
            validate &&
            this.options[key].find((t) => t.hasOwnProperty("amount"));
        }
        return validate;
      });
      const has_correct_main_company =
        this.$props.hasOwnProperty("options") &&
        this.options &&
        this.options.hasOwnProperty("main_company_id") &&
        Number.isInteger(this.options.main_company_id);
      
      return has_correct_options && has_correct_options_values && has_correct_main_company;
    },
    handleOptions() {
      const current_transmissions = this.payload
        ? this.payload.transmissions
        : [];
      this.mountAvailableLinks(current_transmissions);
      this.inputsEnabled = this.options.can_change;
    },
    validateLink(link) {
      const empty_option =
        link.transmission_type_id && !link.company_id && !link.visibility;
      if (empty_option) {
        return false;
      }
      if (!link.company_id) {
        this.$emit("warning", {
          type: "info",
          message: "Você deve selecionar uma empresa para cada link",
        });
        return false;
      }
      if (!link.visibility) {
        this.$emit("warning", {
          type: "info",
          message: "Você deve selecionar o tipo de visibilidade",
        });
        return false;
      }
      return true;
    },
    handleResponse() {
      const formated_links = this.links
        .filter((l) => l.active)
        .map((l) => ({
          transmission_type_id: l.transmission_type?.value,
          company_id: l.company?.value,
          url: l.url,
          title: l.title,
          visibility: l.visibility?.value,
        }));
      const validated_options = formated_links.every((l) => this.validateLink(l));
      if (!validated_options) {
        return null;
      }
      return {
        transmissions: formated_links,
      };
    },
    mountAvailableLinks(current_transmissions) {
      const current_ids = current_transmissions?.map(
        (t) => t?.transmission_type_id
      );
      const available = this.options.transmission_types?.reduce(
        (acc, o, index) => {
          const total = current_ids.filter((c) => c === o.value)?.length ?? 0;
          if (total < o.amount) {
            const diff = o.amount - total;
            const obj = {
              transmission_type: o,
              company: this.options.companies.find((f) => f.value == this.options.main_company_id),
              url: null,
              title: null,
              visibility: this.options.visibilities.find((f) => f.value == "public"),
              active: false
            };
            const arr = acc;
            for (let i = 0; i < diff; i++) {
              arr.push({
                id: `available-${index}-${i}`,
                ...obj,
              });
            }
            return arr;
          }
          return acc;
        },
        []
      );
      const currents = current_transmissions?.map((c, i) => ({
        id: `current-${i}`,
        transmission_type: this.options.transmission_types?.find(
          (f) => f.value == c?.transmission_type_id
        ),
        company: this.options.companies?.find((f) => f.value == c?.company_id),
        url: c.url,
        title: c.title,
        visibility: this.options.visibilities?.find(
          (f) => f.value == c?.visibility
        ),
        active: true
      }));
      this.links = [...currents, ...available];
    },
    onClickSubmit() {
      const payload = this.handleResponse();
      payload && this.$emit("submit", payload);
    },
    emitError() {
      this.$emit("error");
    },
    inputEnable(input) {
      return this.rescheduling ? this.inputsEnabled.some((s) => s == input) : true;
    },
    handleNextStepHotKey(event) {
      if (event.key.toLowerCase() === "p" && event.shiftKey && event.altKey) {
        this.onClickSubmit();
      }
    },
  },
};
</script>

<style scoped>
.c-footer {
  position: absolute;
  bottom: 0px;
}
</style>