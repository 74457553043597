<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="isLoading">
    <div class="mb-5" v-if="internalOptions">
      <b-row>
        <b-col cols="6">
          <b-form-group label="Escolha um evento:">
            <v-select
              v-model="event"
              :options="internalOptions"
              autocomplete="off"
              :clearable="false"
              :disabled="!inputEnable(expectedPayloads[0])"
            />
          </b-form-group>
          <div class="mt-2" v-if="event">
            <b-form-group>
              <div class="d-flex">
                <b-form-checkbox
                  :class="
                    !allRecordedMedia
                      ? 'custom-control-primary'
                      : 'custom-control-info'
                  "
                  v-model="allRecordedMedia"
                  switch
                  :disabled="!inputEnable(expectedPayloads[1])"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
                <span>Evento Todo Gravado</span>
              </div>
            </b-form-group>
            <b-form-group v-if="!allRecordedMedia">
              <div class="d-flex">
                <b-form-checkbox
                  :class="
                    !hasRecordedMedia
                      ? 'custom-control-primary'
                      : 'custom-control-info'
                  "
                  v-model="hasRecordedMedia"
                  switch
                  :disabled="!inputEnable(expectedPayloads[2])"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
                <span>Tem Gravações</span>
              </div>
            </b-form-group>
            <b-form-group v-if="!allRecordedMedia">
              <div class="d-flex">
                <b-form-checkbox
                  :class="
                    !needSupportStudio
                      ? 'custom-control-primary'
                      : 'custom-control-info'
                  "
                  v-model="needSupportStudio"
                  switch
                  :disabled="!inputEnable(expectedPayloads[3])"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
                <span>Necessita de estudio de apoio</span>
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-alert fade class="mb-1 mt-1" variant="warning" :show="isRecurrent">
        <div class="alert-body mr-1">
          <span>Atenção! Este evento é recorrente.</span>
        </div>
      </b-alert>
    </div>
    <div class="c-footer d-block">
      <b-button
        :disabled="loading"
        :class="{ 'px-3': loading }"
        variant="info"
        @click="onClickSubmit"
      >
        <b-spinner v-if="loading" small />
        <span v-else>Próximo</span>
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import appConfig from "@core/app-config/useAppConfig";
import _lessonService from "@/services/lessons-service";
import { BFormGroup, BInputGroup, BSpinner, BAlert } from "bootstrap-vue";

export default {
  setup() {
    const { skin } = appConfig();
    return {
      skin,
    };
  },
  components: {
    BFormGroup,
    BInputGroup,
    BSpinner,
    BAlert,
  },
  watch: {
    allRecordedMedia(value) {
      if (value) {
        this.hasRecordedMedia = false;
        this.needSupportStudio = false;
      }
    }
  },
  data() {
    return {
      isLoading: false,
      event: null,
      internalOptions: null,
      allRecordedMedia: false,
      hasRecordedMedia: false,
      needSupportStudio: false,
      expectedPayloads: ["selected_event", "all_recorded_media", "has_recorded_media", "need_support_studio"],
      expectedOptions: ["events"],
      inputsEnabled: [],
    };
  },
  props: {
    loading: Boolean,
    rescheduling: Boolean,
    payload: Object,
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.startComponent();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleNextStepHotKey);
  },
  computed: {
    isRecurrent() {
      return this.event && this.event.details && this.event.details.recurrence;
    },
  },
  methods: {
    startComponent() {
      // verifica se foi possível lidar com o payload e as options
      if (this.checkOptions() && this.checkPayload()) {
        this.handleOptions();
        this.handlePayload();
      } else {
        this.emitError();
      }
      document.addEventListener("keyup", this.handleNextStepHotKey);
    },
    checkPayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        // payload é opcional
        return this.expectedPayloads.every((key) => key in this.payload);
      }
      return true;
    },
    checkOptions() {
      return (
        this.$props.hasOwnProperty("options") &&
        this.options &&
        this.expectedOptions.every((key) => key in this.options) &&
        Array.isArray(this.options.events) &&
        this.options.events.length > 0 &&
        this.options.events.every(
          (t) =>
            t.hasOwnProperty("value") &&
            t.hasOwnProperty("label") &&
            t.hasOwnProperty("details")
        ) &&
        this.options.hasOwnProperty("can_change")
      );
    },
    handlePayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        this.event = this.internalOptions.find(
          (f) => f.value == this.payload.selected_event
        );
        this.allRecordedMedia = this.payload.all_recorded_media;
        this.hasRecordedMedia = this.payload.has_recorded_media;
        this.needSupportStudio = this.payload.need_support_studio;
      }
    },
    handleOptions() {
      this.internalOptions = this.options.events;
      this.inputsEnabled = this.options.can_change;
    },
    handleResponse() {
      if (this.event) {
        let event = this.internalOptions.find(
          (f) => f.value == this.event.value
        );
        return {
          selected_event: event.value,
          all_recorded_media: this.allRecordedMedia,
          has_recorded_media: this.hasRecordedMedia,
          need_support_studio: this.needSupportStudio,
        };
      } else {
        this.$emit("warning", {
          type: "info",
          message: "Selecione um Evento",
        });
        return null;
      }
    },
    onClickSubmit() {
      const payload = this.handleResponse();
      payload && this.$emit("submit", payload);
    },
    emitError() {
      this.$emit("error");
    },
    inputEnable(input) {
      return this.rescheduling ? this.inputsEnabled.some((s) => s == input) : true;
    },
    handleNextStepHotKey(event) {
      if (event.key.toLowerCase() === "p" && event.shiftKey && event.altKey) {
        this.onClickSubmit();
      }
    },
  },
};
</script>

<style scoped>
.duration {
  max-width: 100px;
}
.c-footer {
  position: absolute;
  bottom: 0px;
}
</style>