<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="mainLoading">
    <b-card no-body class="main-content">
      <b-alert
        v-for="(message, i) in messages"
        :key="i"
        v-height-fade.appear
        dismissible
        :variant="message.type"
        :show="messages.length > 0"
        class="m-25"
      >
        <div class="alert-body">
          <feather-icon icon="InfoIcon" class="mr-50" />
          {{ message.message }}
        </div>
      </b-alert>
      <b-row>
        <b-col md="3" class="summary">
          <div
            v-for="step in orchestrator.steps"
            :key="step.name"
            @click="canAccess(step.name) && changeStep(step.name)"
            :style="{
              cursor: canAccess(step.name) && 'pointer',
              opacity: canAccess(step.name) ? 1 : 0.65,
            }"
            class="d-flex align-items-center mb-1"
          >
            <b-avatar
              rounded="sm"
              class="mr-1"
              :variant="iconVariantHandler(step)"
              v-b-tooltip.hover.top="statusHelper[step.status].label"
            >
              <feather-icon :icon="iconHandler(step)" />
            </b-avatar>
            <div>
              <strong>{{ stepHelper[step.name].name }}</strong> <br />
              <i v-if="step.name !== currentStep.name">
                {{ stepHelper[step.name].summary(step.payload, step.options) }}</i
              >
            </div>
          </div>
        </b-col>
        <b-col md="9" class="component-body">
          <component
            v-if="currentStep && !internalError && orchestrator.id"
            :is="stepHelper[currentStep.name].component"
            :payload="currentStep.payload"
            :orchestratorId="orchestrator.id"
            :options="currentStep.options"
            :loading="loading"
            :rescheduling="rescheduling.isRescheduling"
            :key="`${currentStep.payload}-${currentStep.name}`"
            :runningRecurrences="runningRecurrences"
            @submit="(res) => submitStep(currentStep.name, res)"
            @error="() => (internalError = stepHelper[currentStep.name].name)"
            @warning="addInternalMessage"
          />
          <div
            v-else-if="internalError"
            class="d-flex flex-column justify-content-center align-items-center"
            style="height: 100%"
          >
            <b-alert variant="danger" style="width: 50%" show>
              <div class="alert-body">
                <feather-icon icon="InfoIcon" class="mr-50" />
                <span
                  >Ocorreu um erro inesperado durante a renderização do passo "{{
                    internalError
                  }}". Por favor, entre em contato com a equipe de suporte
                  informando o problema.</span
                >
              </div>
            </b-alert>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BAvatar, VBTooltip, BAlert, BOverlay } from "bootstrap-vue";
import { heightFade } from "@core/directives/animations";
import statusHelper from "./helpers/status";
import stepHelper from "./helpers/steps";
import useAppConfig from "@core/app-config/useAppConfig";
import { _newAppointmentService } from "@/services/appointment-service-v2";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BCard,
    BAvatar,
    BAlert,
    BOverlay
  },
  computed: {
    messages() {
      return this.orchestrator?.messages?.concat(this.internalMessages);
    },
    storage() {
      return this.rescheduling.isRescheduling ? this.storageRef.rescheduling : this.storageRef.new;
    }
  },
  directives: {
    "b-tooltip": VBTooltip,
    heightFade,
  },
  data() {
    return {
      mainLoading: false,
      environment: process.env.VUE_APP_ENVIROMENT,
      orchestrator: {},
      stepHelper: stepHelper,
      statusHelper: statusHelper,
      currentStep: null,
      loading: false,
      storageRef: {
        new: "@intra:current_appointment",
        rescheduling: "@intra:current_rescheduling"
      },
      isCommitAvailable: true,
      internalError: null,
      internalMessages: [],
      rescheduling: {},
      runningRecurrences: false,
      isRecurrence: false,
      allRecurrenceCreated: false,
      someRecurrenceCreated: false,
    };
  },
  async created() {
    this.rescheduling.isRescheduling = this.$route.params.id > 0;
    this.rescheduling.appointmentId = this.$route.params.id;

    await this.initComponent();
  },
  watch: {
    orchestrator(item) {
      if (!item) {
        return;
      }
      this.currentStep = item.steps.find((f) => f.name === item.current_step);
      this.isCommitAvailable = item.steps.every(
        (s) => s.status === "Validated"
      );
      this.recurrenceHandler();
    },
  },
  methods: {
    recurrenceHandler() {
      const recurrenceStep = this.findStepByName("recurrence");
      const detailsStep = this.findStepByName("schedule-details");

      if (!recurrenceStep || !recurrenceStep.payload || !detailsStep || !detailsStep.payload) {
        return;
      }

      this.isRecurrence = true;
      this.allRecurrenceCreated = detailsStep.payload.recurrences.filter((r) => r.appointment_id).length == recurrenceStep.payload.recurrences.length;
      this.someRecurrenceCreated = detailsStep.payload.recurrences.some((r) => r.appointment_id);
    },
    async initComponent() {
      if (this.rescheduling.isRescheduling) {
        await this.reschedule();
      } else {
        const found_appointment_id = this.handleStorage().get();
        if (found_appointment_id) {
          this.mainLoading = true;
          this.$swal({
            title: "Deseja continuar de onde parou?",
            text: "Verificamos que você começou um novo agendamento anteriormente e não concluiu, deseja continuar de onde parou?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Sim, continuar!",
            cancelButtonText: "Começar um novo",
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.getCurrentAppointment(found_appointment_id);
            } else if (result.dismiss === 'cancel') {
              this.handleStorage().remove();
              this.start();
            }
          });
        } else {
          await this.start();
        }
      }
    },
    async start() {
      this.mainLoading = true;
      await _newAppointmentService
        .start()
        .then((res) => {
          this.orchestrator = res;
        })
        .catch((error) => {
          this.addInternalMessage({
            type: "danger",
            message: "Ocorreu um erro durante a criação de um novo fluxo de agendamento. Por favor, entre em contato com o time de suporte.",
          })
        })
        .finally(() => (this.mainLoading = false));
    },
    async reschedule() {
      this.mainLoading = true;
      await _newAppointmentService
        .reschedule(this.rescheduling.appointmentId)
        .then((res) => {
          this.orchestrator = res;
          this.handleStorage().save(res.id);
        })
        .catch((error) => {
          this.addInternalMessage({
            type: "danger",
            message: "Ocorreu um erro durante o carregamento do agendamento. Por favor, entre em contato com o time de suporte.",
          })
          console.log(error)
        })
        .finally(() => (this.mainLoading = false));
    },
    async getCurrentAppointment(id) {
      this.mainLoading = true;
      await _newAppointmentService
        .getCurrent(id)
        .then((res) => {
          this.orchestrator = res;
          this.handleStorage().save(res.id);
        })
        .catch((error) => {
          this.addInternalMessage({
            type: "danger",
            message: "Ocorreu um erro durante o carregamento do fluxo de agendamento. Por favor, entre em contato com o time de suporte.",
          })
        })
        .finally(() => (this.mainLoading = false));
    },
    async submitStep(step_name, step_payload) {
      this.internalMessages = []; // reset messages
      if (!step_payload) {
        this.addInternalMessage({
          type: "warning",
          message: `Preencha corretamente os dados do passo "${stepHelper[step_name].name}"`,
        })
        return;
      }
      this.loading = true;
      const payload = {
        step_name,
        ...step_payload,
      };

      if (this.isRecurrence && this.someRecurrenceCreated && step_name == "schedule-details") {
        this.commitRecurrenceAppointment();
        return;
      }

      await _newAppointmentService
        .submitStep(this.orchestrator.id, payload)
        .then((res) => {
          this.orchestrator = res;
          this.handleStorage().save(res.id);
        })
        .catch((error) => {
          if (!this.environment) {
            // ambiente de produção
            this.addInternalMessage({
              type: "danger",
              message: `Por favor, entre em contato com o time de suporte informando o seguinte código de erro: ${error.response.data.id}`
            })
            console.log(error)
          } else {
            this.addInternalMessage({
              type: "danger",
              message: "Ocorreu um erro durante a requisição. Por favor, entre em contato com o time de suporte.",
            })
            console.log(error)
          }
        })
        .finally(() => {
          if (this.isCommitAvailable) {
            this.commitAppointment();
          } else {
            this.loading = false;
          }
        });
    },
    async commitAppointment() {
      if (this.isRecurrence) {
        this.commitRecurrenceAppointment();
        return;
      }
      this.loading = true;
      await _newAppointmentService
        .commit(this.orchestrator.id)
        .then((res) => {
          this.orchestrator = res;
          if (this.orchestrator.messages.length == 0) {
            this.handleStorage().remove();
            this.$router.push({
              name: "pedagogic-calendar",
              query: { q: res.appointment_id },
            });
          }
        })
        .catch((error) => {
          if (!this.environment) {
            // ambiente de produção
            this.addInternalMessage({
              type: "danger",
              message: `Por favor, entre em contato com o time de suporte informando o seguinte código de erro: ${error.response.data.id}`
            })
            console.log(error)
          } else {
            this.addInternalMessage({
              type: "danger",
              message: "Ocorreu um erro durante a requisição. Por favor, entre em contato com o time de suporte.",
            })
          }
        })
        .finally(() => (this.loading = false));
    },
    async commitRecurrenceAppointment() {
      this.loading = true;
      this.runningRecurrences = true;

      const recurrenceStep = this.findStepByName("recurrence");
      let detailsStep = this.findStepByName("schedule-details");

      for (let index = 1; index <= recurrenceStep.payload.recurrences.length; index++) {

        if (detailsStep.payload.recurrences.some((r) => r.recurrence_order == index && r.appointment_id)) {
          continue;
        }

        try {
          this.orchestrator = await _newAppointmentService.commitRecurrence(this.orchestrator.id, index);
        } catch (error) {
          if (!this.environment) {
            // ambiente de produção
            this.addInternalMessage({
              type: "danger",
              message: `Por favor, entre em contato com o time de suporte informando o seguinte código de erro: ${error.response.data.id}`
            })
            console.log(error)
          } else {
            this.addInternalMessage({
              type: "danger",
              message: "Ocorreu um erro durante a requisição. Por favor, entre em contato com o time de suporte.",
            })
            console.log(error)
          }
        }

        detailsStep = this.findStepByName("schedule-details");
      }

      this.loading = false;
      this.runningRecurrences = false;

      if (detailsStep.payload.recurrences.filter((r) => r.appointment_id).length == recurrenceStep.payload.recurrences.length) {
        this.handleStorage().remove();
        const firstRecurrenceId = detailsStep.payload.recurrences.find((r) => r.appointment_id)?.appointment_id;

        setTimeout(() => {
          this.$router.push({
            name: "pedagogic-calendar",
            query: { q: firstRecurrenceId },
          });
        }, 2000);
      }
    },
    findStepByName(stepName) {
      return this.orchestrator.steps.find((step) => step.name === stepName);
    },
    canAccess(step_name) {
      const found_step = this.findStepByName(step_name);
      const is_available_status = found_step.status == "Validated" || this.orchestrator.current_step == step_name;

      const detailsStep = this.findStepByName("schedule-details");

      if (this.isRecurrence && detailsStep.payload?.recurrences.some((r) => r.appointment_id)) {
        return found_step.can_change;
      } else if (this.rescheduling.isRescheduling) {
        const is_available_change = found_step.can_change;

        if (found_step.name === "project-lesson") {
          const is_available_jobtype = this.isJobTypeAcceptRecordings();
          return is_available_status && is_available_change && is_available_jobtype;
        }
        
        return is_available_status && is_available_change;
      }

      return is_available_status;
    },
    isJobTypeAcceptRecordings() {
      const jobtype_step = this.findStepByName("job-type");
      const selected_job_type = jobtype_step.payload.selected_job_type;
      const jobtype = jobtype_step.options.job_types.find((f) => f.value == selected_job_type);

      return jobtype.details.accept_recordings;
    },
    changeStep(step_name) {
      const is_available = this.canAccess(step_name);
      if (is_available) {
        this.internalError = null;
        this.currentStep = this.findStepByName(step_name);
      }
    },
    handleStorage(key = this.storage) {
      return {
        save: (id) => localStorage.setItem(key, id),
        remove: () => localStorage.removeItem(key),
        get: () => {
          return localStorage.getItem(key);
        },
      };
    },
    addInternalMessage(msg) {
      this.internalMessages = []
      setTimeout(() => {
        this.internalMessages.push(msg)
      }, 100)
    },
    iconHandler (step) {
      let stepIcon = this.statusHelper[step.status].icon || this.stepHelper[step.name].icon;

      if (this.isRecurrence
        && !this.allRecurrenceCreated
        && this.someRecurrenceCreated) {
        
        if (step.name == "schedule-details") {
          stepIcon = "RefreshCwIcon";
        } else if (step.name == "recurrence" && step.options?.enabled_recurrences?.length > 0) {
          stepIcon = "XIcon";
        }
      }
      
      return stepIcon;
    },
    iconVariantHandler (step) {
      let stepVariant = this.statusHelper[step.status].variant;

      if (this.isRecurrence
        && !this.allRecurrenceCreated
        && this.someRecurrenceCreated) {
        
        if (step.name == "schedule-details") {
          stepVariant = "warning";
        } else if (step.name == 'recurrence' && step.options?.enabled_recurrences?.length > 0) {
          stepVariant = "danger";
        }
      }
      
      return stepVariant;
    },
  },
};
</script>

<style scoped>
.main-content {
  min-height: 300px;
  padding: 0px;
}
.summary {
  border-right: 1px solid rgba(34, 41, 47, 0.08);
  padding: 20px 20px 8px 35px;
}
.component-body {
  padding: 20px 35px 20px 20px;
}
</style>