<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="isLoading">
    <!-- Component Body -->
    <div class="mb-5">
      <b-row>
        <b-col cols="6">
          <b-form-group label="Nome">
            <b-form-input
              v-model="appointment_name"
              :disabled="hasCreatedRecurrence"
              placeholder="Informe um nome (opcional)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Descrição">
            <quill-editor
              :options="editorOption"
              :disabled="hasCreatedRecurrence"
              v-model="description" />
          </b-form-group>
        </b-col>

        <b-col cols="12" class="d-flex mt-1">
          <b-form-checkbox
            v-model="notify"
            class="custom-control-success"
            name="check-button"
            :disabled="hasCreatedRecurrence"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="MailIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
          <b-card-text class="mb-0"> Notificar participantes ? </b-card-text>
        </b-col>
      </b-row>
      <div v-if="showRecurrences">
        <div>
          <b-alert fade class="mb-1 mt-1" variant="warning" :show="runningRecurrences">
            <div class="alert-body mr-1">
              <span>Por favor aguarde! As recorrências estão sendo processadas em fila.</span>
              <br />
              <small>Você pode acompanhar o progresso ao lado de cada item na fila.</small>
            </div>
          </b-alert>
          <b-alert fade class="mb-1 mt-1" variant="danger" :show="hasFailedRecurrence && !runningRecurrences">
            <div class="alert-body mr-1">
              <span>Não foi possível criar todas as recorrências!!</span>
              <br />
              <small>Vá ao passo de recorrências e altere o(s) estúdio(s) inválido(s).</small>
            </div>
          </b-alert>
          <b-alert fade class="mb-1 mt-1" variant="success" :show="hasCreatedAllRecurrences && !runningRecurrences">
            <div class="alert-body mr-1">
              <span>Todas as recorrências foram criadas com sucesso!!</span>
            </div>
          </b-alert>
        </div>
        <div class="mt-2"><b>Recorrências:</b></div>
        <div v-for="(rec, index) in recurrences" :key="index">
          <hr v-if="index != 0" />
          <b-row align-v="center">
            <b-col md="6">
              <div>#{{ rec.recurrence_order }} - {{ formatDate(rec.start_date) }}</div>
            </b-col>
            <b-col md="5">
              <b-form-group v-if="!isVipRoom && !isHomeOffice" label="Estúdio">
                <div>{{ rec.studio }}</div>
              </b-form-group>
              <b-form-group v-else>
                <div v-if="isVipRoom">Sala VIP</div>
                <div v-else>Home Office</div>
              </b-form-group>
            </b-col>
            <b-col md="1" class="text-center">
              <div v-if="rec.status.appointment_id">
                <strong @click="$router.push({ name: 'pedagogic-calendar', query: { q: rec.status.appointment_id } })" style="cursor: pointer;">
                  #{{ rec.status.appointment_id }}
                </strong>
                <b-avatar
                  :id="`status-app-recorrence-${rec.recurrence_order}`"
                  variant="success"
                  size="25"
                >
                  <feather-icon icon="CheckIcon" size="16" />
                </b-avatar>
              </div>
              <div v-else-if="rec.status.error">
                <feather-icon
                  :id="`status-app-recorrence-${rec.recurrence_order}`"
                  icon="BellIcon"
                  size="21"
                  class="text-danger"
                  badge="1"
                  badge-classes="badge-danger"
                />
                <b-tooltip
                  triggers="hover"
                  :target="`status-app-recorrence-${rec.recurrence_order}`"
                  :title="`${rec.status.error}`"
                  :delay="{ show: 100, hide: 50 }"
                />
              </div>
              <div v-else-if="rec.status.loading">
                <b-spinner type="grow"/>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="c-footer d-block">
      <b-button
        v-if="showSubmitButton"
        variant="info"
        :disabled="loading || runningRecurrences"
        :class="{ 'px-3': loading }"
        @click="onClickSubmit"
      >
        <b-spinner v-if="loading" small />
        <span v-else-if="isRecurrence && hasCreatedRecurrence && !hasCreatedAllRecurrences">Reprocessar</span>
        <span v-else>Finalizar</span>
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import appConfig from "@core/app-config/useAppConfig";
import { BSpinner, BAlert } from "bootstrap-vue";

export default {
  setup() {
    const { skin } = appConfig();
    return {
      skin,
    };
  },
  components: {
    BSpinner,
    BAlert
  },
  data() {
    return {
      isLoading: false,
      appointment_name: null,
      description: null,
      notify: true,
      expectedPayloads: ["notify"],
      recurrences: [],
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ font: [] }],
            [{ color: [] }],
          ],
        },
        placeholder: "Informe uma breve descrição (opcional)",
      },
      isRecurrence: false,
      isVipRoom: false,
      isHomeOffice: false,
    };
  },
  props: {
    loading: Boolean,
    payload: Object,
    options: Object,
    runningRecurrences: Boolean
  },
  mounted() {
    this.startComponent();
  },
  watch: {
    payload() {
      this.handleOptions();
      this.handlePayload();
      this.recurrencesLoading();
    },
    runningRecurrences(isLoading) {
      if (!isLoading) {
        return;
      }

      this.recurrences?.forEach((rec) => {
          if (!rec.status.appointment_id || rec.status.appointment_id == 0) {
            rec.status.loading = true;
            rec.status.error = null;
          }
      });
    }
  },
  methods: {
    startComponent() {
      // verifica se foi possível lidar com o payload e as options
      if (this.checkPayload()) {
        this.handleOptions();
        this.handlePayload();
      } else {
        this.emitError();
      }
    },
    checkPayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        return this.expectedPayloads.every((key) => key in this.payload);
      } else {
        return true;
      }
    },
    handleOptions() {
      if (this.options
        && this.options.hasOwnProperty("recurrences")
        && this.options.recurrences
        && this.options.recurrences.length > 0) {
          this.isVipRoom = this.options.is_vip_room;
          this.isHomeOffice = this.options.is_home_office;
          this.isRecurrence = true;
          this.recurrences = this.options.recurrences.map((rec) => ({
            ...rec,
            status: {
              loading: false,
              ...this.getRecurrenceFromPayload(rec.recurrence_order),
            }
          }));
      }
    },
    getRecurrenceFromPayload(recurrenceOrder) {
      if (this.payload && this.payload.hasOwnProperty("recurrences")) {
        var recurrence = this.payload.recurrences.find((rec) => rec.recurrence_order === recurrenceOrder);
        if (recurrence) {
          return recurrence;
        }
      }

      return {
        error: null,
        appointment_id: null,
      };
    },
    handlePayload() {
      if (this.payload) {
        (this.appointment_name = this.payload.appointment_name),
        (this.description = this.payload.description),
        (this.notify = this.payload.notify);
      }
    },
    handleResponse() {
      return {
        appointment_name: this.appointment_name,
        description: this.description,
        notify: this.notify,
      };
    },
    onClickSubmit() {
      const payload = this.handleResponse();
      this.recurrencesLoading();
      payload && this.$emit("submit", payload);
    },
    emitError() {
      this.$emit("error");
    },
    formatDate(dateTime) {
      const [date, time] = dateTime.split("T")
      return `${date.split("-").reverse().join("/")} às ${time.slice(0,5).replace(':', 'h')}`;
    },
    recurrencesLoading() {
      if (!this.payload) {
        return;
      }

      this.recurrences?.forEach((rec) => {
        if (this.runningRecurrences) {
          if (!rec.status.appointment_id && (!rec.status.error || rec.status.error.length == 0)) {
            rec.status.loading = true;
          } else {
            rec.status.loading = false;
          }
        } else {
          rec.status.loading = false;
        }
      });
    },
  },
  computed: {
    showRecurrences() {
      return this.$props.hasOwnProperty("options")
        && this.options
        && this.options.hasOwnProperty("recurrences")
        && this.options.recurrences
        && this.options.recurrences.length > 0;
    },
    showSubmitButton(){
      if (!this.$props.hasOwnProperty("options")
        || !this.options
        || !this.options.hasOwnProperty("recurrences")
        || !this.options.recurrences
        || this.options.recurrences.length <= 0) {
          return true;
      }
      return !this.hasCreatedAllRecurrences && !this.hasFailedRecurrence
    },
    hasCreatedRecurrence() {
      return this.recurrences?.some((rec) => rec.status.appointment_id);
    },
    hasCreatedAllRecurrences() {
      var totalRecurrences = this.options?.recurrences?.length ?? 0;
      if (totalRecurrences == 0) {
        return false;
      }
      var createdLength = this.recurrences?.filter((rec) => rec.status.appointment_id).length ?? 0;
      return totalRecurrences == createdLength;
    },
    hasFailedRecurrence() {
      return this.recurrences?.some((rec) => rec.status.error);
    },
  },
};
</script>
<style scoped>
.c-footer {
  position: absolute;
  bottom: 0px;
}
</style>