<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="isLoading">
    <b-form-group class="mb-5" label="Escolha um tipo de demanda:">
      <b-row>
        <b-col md="6">
          <v-select
            v-model="jobType"
            :options="options.job_types"
            :clearable="false"
            :disabled="!inputEnable(expectedPayloads[0])"
          >
            <template #option="data">
              <div class="d-flex justify-content-between">
                <span>{{ data.label }}</span>
                <b-badge
                  variant="light-primary"
                  class="d-flex justify-content-between"
                  style="min-width: 100px"
                >
                  <template v-if="data.details.setup_time">
                    <feather-icon icon="ClockIcon" class="mr-25" />
                    <span>{{ data.details.setup_time }} minutos</span>
                  </template>
                  <span v-else>sem preparação</span>
                </b-badge>
              </div>
            </template>
          </v-select>
        </b-col>
        <b-col class="px-3" v-if="jobType">
          <div class="info-content">
            <div class="tooltips-itens" v-if="jobType.details.available_to">
              Disponível para:
              <b-badge
                v-for="(type, index) in jobType.details.available_to"
                :key="index"
                variant="light-warning"
                class="tooltips-badge"
              >
                {{ type }}
              </b-badge>
            </div>

            <div v-if="jobType.details.format_types" class="tooltips-itens">
              Tipos de Formato:
              <b-badge
                variant="light-success"
                class="tooltips-badge"
                v-for="(format, index) in jobType.details.format_types"
                :key="index"
              >
                {{ format }}
              </b-badge>
            </div>

            <div
              v-if="jobType.details.transmission_types"
              class="tooltips-itens"
            >
              Tipos de Transmissão:
              <b-badge
                variant="light-danger"
                class="tooltips-badge"
                v-for="(transmission, index) in jobType.details
                  .transmission_types"
                :key="index"
              >
                {{ transmission }}
              </b-badge>
            </div>

            <div class="tooltips-itens">
              Obrigatório Upload Material:
              <b>
                {{ jobType.details.required_upload_material ? "Sim" : "Não" }}
              </b>
            </div>

            <div class="tooltips-itens">
              Aceita extrapolação de horário:
              <b>
                {{ jobType.details.accept_time_extrapolation ? "Sim" : "Não" }}
              </b>
            </div>

            <div class="tooltips-itens" v-if="jobType.details.period_name">
              Período:
              <b> {{ jobType.details.period_name }} </b>
            </div>

            <div class="tooltips-itens">
              Aceita material gravado:
              <b>
                {{ jobType.details.accept_recordings ? "Sim" : "Não" }}
              </b>
            </div>

            <div
              class="tooltips-itens"
              v-if="jobType.details.number_of_operators"
            >
              Quantidade de operadores necessários:
              <b> {{ jobType.details.number_of_operators }} </b>
            </div>

            <div class="tooltips-itens" v-if="jobType.details.setup_time">
              Tempo de preparação:
              <b>
                {{ jobType.details.setup_time }}
                (Minutos)
              </b>
            </div>

            <div
              class="tooltips-itens"
              v-if="jobType.details.minimum_of_studios"
            >
              Mínimo de estúdio:
              <b> {{ jobType.details.minimum_of_studios }} </b>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-form-group>
    <div class="c-footer d-block">
      <b-button
        :disabled="loading"
        :class="{ 'px-3': loading }"
        variant="info"
        @click="onClickSubmit"
      >
        <b-spinner v-if="loading" small />
        <span v-else>Próximo</span>
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import appConfig from "@core/app-config/useAppConfig";
import { BButton, BTooltip, VBTooltip, BSpinner, BBadge } from "bootstrap-vue";

export default {
  setup() {
    const { skin } = appConfig();
    return {
      skin,
    };
  },
  components: {
    BButton,
    BTooltip,
    BSpinner,
    BBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      jobType: null,
      expectedPayloads: ["selected_job_type"],
      expectedOptions: ["job_types"],
      inputsEnabled: [],
      animated: false,
    };
  },
  props: {
    loading: Boolean,
    rescheduling: Boolean,
    payload: Object,
    options: {
      type: Object,
      required: true,
    },
  },
  watch: {
    jobType() {
      this.animated = true;
      setTimeout(() => {
        this.animated = false;
      }, 1000);
    },
  },
  mounted() {
    this.startComponent();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleNextStepHotKey);
  },
  methods: {
    startComponent() {
      // verifica se foi possível lidar com o payload e as options
      if (this.checkOptions() && this.checkPayload()) {
        this.handleOptions();
        this.handlePayload();
      } else {
        this.emitError();
      }
      document.addEventListener("keyup", this.handleNextStepHotKey);
    },
    checkPayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        // payload é opcional
        return this.expectedPayloads.every((key) => key in this.payload);
      }
      return true;
    },
    checkOptions() {
      return (
        this.$props.hasOwnProperty("options") &&
        this.options &&
        this.expectedOptions.every((key) => key in this.options) &&
        Array.isArray(this.options.job_types) &&
        this.options.job_types.length > 0 &&
        this.options.job_types.every(
          (t) =>
            t.hasOwnProperty("value") &&
            t.hasOwnProperty("label") &&
            t.hasOwnProperty("details")
        ) &&
        this.options.hasOwnProperty("can_change")
      );
    },
    handlePayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        this.jobType = this.options.job_types.find(
          (f) => f.value === this.payload.selected_job_type
        );
      }
    },
    handleResponse() {
      if (this.jobType && this.jobType.value) {
        return {
          selected_job_type: this.jobType.value,
        };
      } else {
        this.$emit("warning", {
          type: "info",
          message: "Selecione um Tipo de Demanda",
        });
        return null;
      }
    },
    handleOptions() {
      this.options.job_types.sort(this.orderBy);
      this.inputsEnabled = this.options.can_change;
    },
    onClickSubmit() {
      let response = this.handleResponse();
      if (response) {
        this.$emit("submit", response);
      }
    },
    emitError() {
      this.$emit("error");
    },
    orderBy(a, b) {
      return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
    },
    inputEnable(input) {
      return this.rescheduling
        ? this.inputsEnabled.some((s) => s == input)
        : true;
    },
    handleNextStepHotKey(event) {
      if (event.key.toLowerCase() === "p" && event.shiftKey && event.altKey) {
        this.onClickSubmit();
      }
    },
  },
};
</script>

<style>
.tooltip-inner {
  max-width: 500px !important;
  text-align: left !important;
}
</style>
<style scoped>
.info-content {
  padding: 10px;
  border: 2px dashed #dddddd;
}
.tooltips-itens {
  margin: 2px;
}
.tooltips-badge {
  margin-left: 3px;
}
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.c-footer {
  position: absolute;
  bottom: 0px;
}
</style>