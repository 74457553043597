import HttpService from './HttpService'

const _http = new HttpService()
export default {
  show: (courseid) => {
    return _http.get(`/api/lesson?courseid=${courseid}`)
  },
  autoComplete: (search) => {
    return _http.get(`/api/lesson/auto-complete?page=${0}&search=${search}&asc`)
  },
  find: (id) => {
    return _http.get(`/api/lesson/${id}`)
  },
  update: (payload) => {
    return _http.put('/api/lesson', payload)
  },
  cancel: (id) => {
    return _http.post(`/api/lesson/cancel/${id}`)
  },
  cancelWithReason: (payload) => {
    return _http.post(`/api/lesson/cancel-reason`, payload)
  },
  getLessonTeacher: (teacher, project = null, course = null, discipline = null, scheduled = null) => {
    let url = `/api/lesson/get-lesson-teacher?data.teacher_id=${teacher}`
    if (project && project > 0)
      url += `&data.project_id=${project}`
    if (course && course > 0)
      url += `&data.course_id=${course}`
    if (discipline && discipline > 0)
      url += `&data.discipline_id=${discipline}`
    if (scheduled != null)
      url += `&data.scheduled=${scheduled}`

    return _http.get(url)
  },
  showLessonDetails: (lesson_id = null,appointment_id = null) => {
    let _parans = `?`;
    if (lesson_id) {
      _parans += `&lesson_id=${lesson_id}`;
    }
    if (appointment_id) {
      _parans += `&appointment_id=${appointment_id}`;
    }
    return _http.get(`/api/lesson/show-lesson-details${_parans}`)
  },  
  saveDuration: (id, payload) => {
    return _http.patch(`/api/lesson?id=${id}`, payload);
  },
}