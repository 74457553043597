<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="isLoading">
    <!-- Component Body -->
    <strong>Classificações</strong>
    <b-row class="mt-2">
      <b-col>
          <b-button
            @click="modalClassification.isOpen = true"
            variant="outline-info"
            size="sm"
          >
            {{ categories.items.length > 0 ? 'Editar Classificações' : 'Classificar' }}
          </b-button>
      </b-col>
    </b-row>

    <hr />

    <span v-if="categories.items.length === 0">Nenhuma classificação encontrada para esse agendamento.</span>

    <b-row v-for="(classification, index) in categories.items" :key="`${index}-${classification.name}`">
        <b-col md="12"><strong>{{ classification.name }}</strong></b-col>
        <b-col class="mb-1" v-if="classification.classifications.length > 0">
            <b-badge v-for="(child, ichd) in classification.classifications" :key="`${ichd}-${classification.name}`" class="mr-50" variant="light-info">
              {{ child.name }}
            </b-badge>
        </b-col>
        <b-col v-else class="mb-1">
            nenhuma classificação
        </b-col>
    </b-row>

    <classification-modal
      v-if="modalClassification.isOpen"
      title="Classificar Agendamento"
      :company-id="companyId"
      :classification-root="modalClassification.classificationsRoot"
      @close="modalClassification.isOpen = false"
      @confirm="onClickConfirm"
    />

    <div class="c-footer d-block">
      <b-button
        :disabled="loading"
        :class="{ 'px-3': loading }"
        variant="info"
        @click="onClickSubmit"
      >
        <b-spinner v-if="loading" small />
        <span v-else>Próximo</span>
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import ClassificationModal from '@/components/shared/modals/classifications/classification-modal.vue'
import appConfig from "@core/app-config/useAppConfig";
import { BSpinner } from "bootstrap-vue";

export default {
  setup() {
    const { skin } = appConfig();
    return {
      skin,
    };
  },
  components: {
    ClassificationModal,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      categories: { items: [] },
      expectedOptions: ["categories"],
      projectId: 0,
      companyId: 0,
      modalClassification: {
        canClassify: false,
        loading: false,
        isOpen: false,
        classificationsRoot: [],
        classifications: []
      }
    };
  },
  props: {
    loading: Boolean,
    rescheduling: Boolean,
    payload: Object,
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.startComponent();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleNextStepHotKey);
  },
  methods: {
    startComponent() {
      if (this.checkOptions()) {
        this.handleOptions();
      } else {
        this.emitError();
      }
      document.addEventListener("keyup", this.handleNextStepHotKey);
    },
    checkOptions() {
      return this.options?.hasOwnProperty("can_change") && this.$props?.hasOwnProperty("options");
    },
    handleOptions() {
      this.categories = this.options.categories;
      this.projectId = this.options.project_id;
      this.companyId = this.options.company_id;

      this.modalClassification.classificationsRoot = this.categories.items.map(m => {
        return {
          children: m.classifications,
          name: m.name,
          id:m.id
        }
      })

      if (this.categories?.items?.length > 0) {
        this.modalClassification.classifications = []
        this.categories.items.forEach(item => {
          if (item?.classifications?.length > 0) {
            item.classifications.forEach(classification => {
              this.modalClassification.classifications.push({
                name: classification.name,
                id: classification.id,
                extra: classification.type === "extra"
              });
            });
          }
        });
      }
    },
    handleResponse() {
      return {
        inherited: this.modalClassification.classifications.filter(f => !f.extra).map(m => m.id),
        extras: this.modalClassification.classifications.filter(f => f.extra).map(m => m.id)
      }
    },
    onClickSubmit() {
      const payload = this.handleResponse();
      payload && this.$emit("submit", payload);
    },
    emitError() {
      this.$emit("error");
    },
    onClickConfirm({ classificationsRoot, classifications }) {
      this.modalClassification.classificationsRoot = classificationsRoot
      this.modalClassification.classifications =  classifications
      this.modalClassification.isOpen = false

      this.categories.items = classificationsRoot.map(m => {
        return {
          classifications: m.children,
          name: m.name,
          id:m.id
        }
      })
    },
    handleNextStepHotKey(event) {
      if (event.key.toLowerCase() === "p" && event.shiftKey && event.altKey) {
        this.onClickSubmit();
      }
    },
  },
};
</script>

<style scoped>
.c-footer {
  position: absolute;
  bottom: 0;
}
</style>