<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="isLoading">
    <!-- Component Body -->
    <div class="mb-5">
      <div v-for="(rec, index) in recurrences" :key="index">
        <hr v-if="index != 0" />
        <b-row align-v="center">
          <b-col md="6">
            <div>Recorrencia #{{ rec.recurrence.recurrence_order }} - {{ formatDate(rec.recurrence.start_date) }}</div>
          </b-col>
          <b-col md="6">
            <b-form-group v-if="!isVipRoom && !isHomeOffice" label="Estúdio">
              <v-select
                v-model="rec.studio"
                :options="rec.search.studios"
                :clearable="true"
                autocomplete="off"
                :filterable="false"
                @search="
                  (search) => {
                    getRecurrenceStudios(search, rec);
                  }
                "
                :loading="isLoadingStudios"
                :disabled="inputDisabled(rec.recurrence.recurrence_order)"
              />
            </b-form-group>
            <b-form-group v-else>
              <div v-if="isVipRoom">Sala VIP</div>
              <div v-else>Home Office</div>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="c-footer d-block">
      <b-button
        v-if="canSubmit"
        :disabled="loading"
        :class="{ 'px-3': loading }"
        variant="info"
        @click="onClickSubmit"
      >
        <b-spinner v-if="loading" small />
        <span v-else>Próximo</span>
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import appConfig from "@core/app-config/useAppConfig";
import _lessonService from "@/services/lessons-service";
import { _newAppointmentService } from "@/services/appointment-service-v2";
import { BFormGroup, BInputGroup, BSpinner } from "bootstrap-vue";

export default {
  setup() {
    const { skin } = appConfig();
    return {
      skin,
    };
  },
  components: {
    BFormGroup,
    BInputGroup,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      recurrences: [],
      isLoadingStudios: false,
      autoCompleteTimer: null,
      internalPayload: [],
      expectedPayloads: ["recurrences"],
      expectedOptions: ["studios", "enabled_recurrences", "can_submit"],
      enabledRecurrences: [],
      canSubmit: true,
      isVipRoom: false,
      isHomeOffice: false,
    };
  },
  props: {
    loading: Boolean,
    rescheduling: Boolean,
    payload: Object,
    options: {
      type: Object,
      required: true,
    },
    orchestratorId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.startComponent();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleNextStepHotKey);
  },
  methods: {
    startComponent() {
      // verifica se foi possível lidar com o payload e as options
      if (this.checkOptions() && this.checkPayload()) {
        this.handleOptions();
        this.handlePayload();
        this.mountAvailableInputs();
      } else {
        this.emitError();
      }
      document.addEventListener("keyup", this.handleNextStepHotKey);
    },
    checkPayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        // payload é opcional
        return this.expectedPayloads.every((key) => key in this.payload);
      }
      return true;
    },
    checkOptions() {
      return (
        this.$props.hasOwnProperty("options") &&
        this.options &&
        this.expectedOptions.every((key) => key in this.options) &&
        this.options.studios?.every(
          (t) => t.hasOwnProperty("value") && t.hasOwnProperty("label")
        )
      );
    },
    handlePayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload)
      {
        this.isVipRoom = this.payload.is_vip_room;
        this.isHomeOffice = this.payload.is_home_office;
        for (const recurrence of this.payload.recurrences) {
          this.internalPayload.push({
            studio: this.options.studios.find((f) => f.value == recurrence.studio_id),
            recurrence: recurrence,
          });
        }
      }
    },
    handleOptions() {
      this.enabledRecurrences = this.options.enabled_recurrences;
      this.canSubmit = this.options.can_submit;
    },
    handleResponse() {
      let payload = { recurrences: [] };
      for (const rec of this.recurrences) {
          payload.recurrences.push({
            recurrence_order: rec.recurrence.recurrence_order,
            studio_id: rec.studio.value,
            start_date: rec.recurrence.start_date,
            validated: !this.enabledRecurrences.includes(rec.recurrence.recurrence_order.toString()),
          });
      }
      return payload;
    },
    mountAvailableInputs() {
      this.recurrences = this.payload.recurrences.map((opt) => ({
        studio: this.options.studios.find((f) => f.value == opt.studio_id),
        recurrence: opt,
        search: {
          loading: false,
          studios: []
        }
      }));
    },
    onClickSubmit() {
      const payload = this.handleResponse();
      payload && this.$emit("submit", payload);
    },
    emitError() {
      this.$emit("error");
    },
    inputDisabled(input) {
      return !this.enabledRecurrences.some((s) => s == input);
    },
    getRecurrenceStudios(search, rec) {
      if (search && search.length >= 3) {
        clearTimeout(this.autoCompleteTimer);
        this.isLoadingStudios = true;
        this.autoCompleteTimer = setTimeout(() => {
          _newAppointmentService
            .getRecurrenceStudios(this.orchestratorId, rec.recurrence.recurrence_order, search)
            .then((res) => {
              rec.search.studios = res;
            })
            .catch((error) => {
              this.$emit("warning", {
                type: "danger",
                message:
                  "Ocorreu um erro durante a listagem de estúdios. Por favor, entre em contato com o time de suporte.",
              });
              console.log(error);
            })
            .finally(() => (this.isLoadingStudios = false));
        }, 1500);
      }
    },
    formatDate(dateTime) {
      const [date, time] = dateTime.split("T")
      return `${date.split("-").reverse().join("/")} às ${time.slice(0,5).replace(':', 'h')}`;
    },
    handleNextStepHotKey(event) {
      if (event.key.toLowerCase() === "p" && event.shiftKey && event.altKey) {
        this.onClickSubmit();
      }
    },
  },
};
</script>

<style scoped>
.duration {
  max-width: 100px;
}
.c-footer {
  position: absolute;
  bottom: 0px;
}
</style>