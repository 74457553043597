<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="isLoading">
    <!-- Component Body -->
    <div class="mb-5">
      <b-row class="mb-1">
        <b-col md="6">
          <b-form-group label="Escolha um projeto:">
            <v-select
              v-model="selectedProject"
              :options="projects"
              :clearable="false"
              autocomplete="off"
              :filterable="false"
              @search="
                (res) => {
                  getAutoCompleteProjects(res);
                }
              "
              :loading="isLoadingProjects"
              :disabled="!inputEnable(expectedPayloads[0])"
            >
              <template v-slot:option="option">
                <div class="mb-0 text-uppercase">
                  {{ option.label }}
                </div>
                <div v-if="option.details.company_name">
                  <b-badge :style="`background: ${option.details.company_color}`"
                    ><feather-icon icon="HomeIcon" size="21"
                  /></b-badge>
                  <small>
                    {{ option.details.company_name }}
                  </small>
                </div>
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Pesquisando... <em>{{ search }}</em>
                </template>
                <em style="opacity: 0.5" v-else>Digite o nome do Projeto...</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="selectedProject" class="px-25 mb-3">
        <b-col md="6">
          <div class="d-flex justify-content-between">
            <div>
              <h5 class="mb-0 text-uppercase">
                {{ selectedProject.label }}
              </h5>
              <div v-if="selectedProject.details">
                <div>
                  <b-badge :style="`background: ${selectedProject.details.company_color}`">
                    <feather-icon icon="HomeIcon" size="21" />
                  </b-badge>
                  <small>
                    {{ selectedProject.details.company_name }}
                  </small>
                </div>
              </div>
            </div>
            <b-button
              variant="outline-info"
              :to="`/pedagogic/project-view/${selectedProject.value}`"
            >
              <span class="align-middle">Ver Projeto</span>
              <feather-icon icon="ChevronRightIcon" class="ml-50" />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="c-footer d-block">
      <b-button
        :disabled="loading"
        :class="{ 'px-3': loading }"
        variant="info"
        @click="onClickSubmit"
      >
        <b-spinner v-if="loading" small />
        <span v-else>Próximo</span>
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import { _newAppointmentService } from "@/services/appointment-service-v2";
import { BCard, BSpinner } from "bootstrap-vue";
import appConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { skin } = appConfig();
    return {
      skin,
    };
  },
  components: {
    BCard,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      isLoadingProjects: false,
      autoCompleteTimer: null,
      projects: [],
      selectedProject: null,
      expectedPayloads: ["selected_project"],
      expectedOptions: [],
      inputsEnabled: []
    };
  },
  props: {
    loading: Boolean,
    rescheduling: Boolean,
    payload: Object,
    options: {
      type: Object,
      required: true,
    },
    orchestratorId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.startComponent();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleNextStepHotKey);
  },
  methods: {
    startComponent() {
      // verifica se foi possível lidar com o payload e as options
      if (this.checkOptions() && this.checkPayload()) {
        this.handleOptions();
        this.handlePayload();
      } else {
        this.emitError();
      }
      document.addEventListener("keyup", this.handleNextStepHotKey);
    },
    checkPayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        return this.expectedPayloads.every((key) => key in this.payload)
      }
      return true;
    },
    checkOptions() {
      return (
        this.$props.hasOwnProperty("options") &&
        this.options &&
        this.expectedOptions.every((key) => key in this.options) &&
        this.options.hasOwnProperty("can_change")
      )
    },
    handlePayload() {
      if (this.options.selected_project) {
        this.selectedProject = this.options.selected_project;
      }
    },
    handleOptions() {
      this.inputsEnabled = this.options.can_change;
    },
    handleResponse(data = this.selectedProject) {
      if (data && data.value) {
        return {
          selected_project: data.value,
        };
      } else {
        this.$emit("warning", {
          type: "info",
          message: "Selecione um projeto",
        });
        return;
      }
    },
    onClickSubmit() {
      const payload = this.handleResponse();
      payload && this.$emit("submit", payload);
    },
    emitError() {
      this.$emit("error");
    },
    getAutoCompleteProjects(search) {
      if (search && search.length >= 3) {
        clearTimeout(this.autoCompleteTimer);
        this.isLoadingProjects = true;
        this.autoCompleteTimer = setTimeout(() => {
          _newAppointmentService
            .getProjects(this.orchestratorId, search)
            .then((res) => {
              this.projects = res;
            })
            .catch((error) => {
              this.$emit("warning", {
                type: "danger",
                message: "Ocorreu um erro durante a listagem de projetos. Por favor, entre em contato com o time de suporte.",
              })
              console.log(error)
            })
            .finally(() => (this.isLoadingProjects = false));
        }, 1500);
      }
    },
    inputEnable(input) {
      return this.rescheduling ? this.inputsEnabled.some((s) => s == input) : true;
    },
    handleNextStepHotKey(event) {
      if (event.key.toLowerCase() === "p" && event.shiftKey && event.altKey) {
        this.onClickSubmit();
      }
    },
  },
};
</script>

<style lang="scss">
.tooltip-inner {
  max-width: 500px !important;
  text-align: left !important;
}

.tooltips-itens {
  margin: 2px;
}

.c-footer {
  position: absolute;
  bottom: 0px;
}
</style>