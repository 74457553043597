const status = {
    Validated: {
        label: "Confirmado",
        variant: "success",
        icon: "CheckIcon"
    },
    Pending: {
        label: "Pendente",
        variant: "secondary",
    },
    Invalided: {
        label: "Inválido",
        variant: "danger",
        icon: "XIcon"
    },
    Disabled: {
        label: "Desabilitado",
        variant: "dark",
    }
};

export default status;