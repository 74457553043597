import HttpService from './HttpService'

const _http = new HttpService()
export const _newAppointmentService = {
    start: () => {
        return _http.get(`/api/appointment/Orchestrator/start`)
    },
    reschedule: (id) => {
        return _http.get(`/api/appointment/Orchestrator/start/${id}`)
    },
    submitStep: (id, payload) => {
        return _http.post(`/api/appointment/Orchestrator/${id}/submit/${payload.step_name}` , payload)
    },
    commit: (id) => {
        return _http.put(`/api/appointment/Orchestrator/${id}/commit`)
    },
    commitRecurrence: (id, recurrenceOrder) => {
        return _http.put(`/api/appointment/Orchestrator/${id}/commit/${recurrenceOrder}`)
    },
    getCurrent: (id) => {
        return _http.get(`/api/appointment/Orchestrator/${id}`)
    },
    getProjects: (id, search) => {
        return _http.get(`/api/appointment/Orchestrator/${id}/project/search/${search}`)
    },
    getStudios: (id, search) => {
        return _http.get(`/api/appointment/Orchestrator/${id}/studio/search/${search}`)
    },
    getSupportStudios: (id, subEventId, search) => {
        return _http.get(`/api/appointment/Orchestrator/${id}/sub-event/${subEventId}/studio/search/${search}`)
    },
    getRecurrenceStudios: (id, recurrenceOrder, search) => {
        return _http.get(`/api/appointment/Orchestrator/${id}/recurrence/${recurrenceOrder}/studio-search/${search}`)
    }
}