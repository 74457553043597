<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="isLoading">
    <!-- Component Body -->
    <div class="mb-5">
      <div v-for="(sub, index) in supports" :key="index">
        <hr v-if="index != 0" />
        <b-row align-v="center">
          <b-col md="6">
            <b-form-group label="Sub-Evento">
              <v-select
                v-model="sub.sub_event"
                :options="options.sub_events"
                autocomplete="off"
                :clearable="false"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Estúdio de Apoio">
              <v-select
                v-model="sub.studio"
                :options="sub.search.studios"
                :clearable="true"
                autocomplete="off"
                :filterable="false"
                @search="
                  (search) => {
                    getSupportStudios(search, sub);
                  }
                "
                :loading="isLoadingStudios"
                :disabled="!inputEnable(expectedPayloads[0])"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <p class="text-center align-bottom pt-3" v-if="supports.length == 0">
        <feather-icon class="mb-25 mr-25" size="20" icon="CoffeeIcon" />
        Não há nenhum sub-evento disponível para seleção
      </p>
    </div>
    <div class="c-footer d-block">
      <b-button
        :disabled="loading"
        :class="{ 'px-3': loading }"
        variant="info"
        @click="onClickSubmit"
      >
        <b-spinner v-if="loading" small />
        <span v-else>Próximo</span>
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import appConfig from "@core/app-config/useAppConfig";
import _lessonService from "@/services/lessons-service";
import { _newAppointmentService } from "@/services/appointment-service-v2";
import { BFormGroup, BInputGroup, BSpinner } from "bootstrap-vue";

export default {
  setup() {
    const { skin } = appConfig();
    return {
      skin,
    };
  },
  components: {
    BFormGroup,
    BInputGroup,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      supports: [],
      isLoadingStudios: false,
      autoCompleteTimer: null,
      internalPayload: [],
      expectedPayloads: ["sub_events"],
      expectedOptions: ["sub_events", "studios"],
      inputsEnabled: [],
    };
  },
  props: {
    loading: Boolean,
    rescheduling: Boolean,
    payload: Object,
    options: {
      type: Object,
      required: true,
    },
    orchestratorId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.startComponent();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleNextStepHotKey);
  },
  methods: {
    startComponent() {
      // verifica se foi possível lidar com o payload e as options
      if (this.checkOptions() && this.checkPayload()) {
        this.handleOptions();
        this.handlePayload();
        this.mountAvailableInputs();
      } else {
        this.emitError();
      }
      document.addEventListener("keyup", this.handleNextStepHotKey);
    },
    checkPayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        // payload é opcional
        return this.expectedPayloads.every((key) => key in this.payload);
      }
      return true;
    },
    checkOptions() {
      return (
        this.$props.hasOwnProperty("options") &&
        this.options &&
        this.expectedOptions.every((key) => key in this.options) &&
        Array.isArray(this.options.sub_events) &&
        this.options.sub_events.length > 0 &&
        this.options.sub_events.every(
          (t) => t.hasOwnProperty("value") && t.hasOwnProperty("label")
        ) &&
        this.options.studios?.every(
          (t) => t.hasOwnProperty("value") && t.hasOwnProperty("label")
        ) &&
        this.options.hasOwnProperty("can_change")
      );
    },
    handlePayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload)
      {
        for (const pay of this.payload.sub_events) {
          this.internalPayload.push({
            studio: this.options.studios.find((f) => f.value == pay.studio_id),
            sub_event: this.options.sub_events.find(
              (f) => f.value == pay.sub_event_id
            ),
          });
        }
      }
    },
    handleOptions() {
      this.inputsEnabled = this.options.can_change;
    },
    handleResponse() {
      let payload = { sub_events: [] };
      for (const sup of this.supports) {
        if (sup.sub_event && sup.studio) {
          payload.sub_events.push({
            studio_id: sup.studio.value,
            sub_event_id: sup.sub_event.value,
          });
        }
      }
      return payload;
    },
    mountAvailableInputs() {
      this.supports = this.options.sub_events.filter(f => !f.used).map((opt) => ({
        studio: null,
        sub_event: opt,
        search: {
          loading: false,
          studios: []
        }
      }));

      if (this.$props.hasOwnProperty("payload") && this.payload) {
        for (let sup of this.supports) {
          let input = this.internalPayload.find(
            (f) => f.sub_event.value == sup.sub_event.value
          );
          if (input) sup.studio = input.studio;
        }
      }
    },
    onClickSubmit() {
      const payload = this.handleResponse();
      payload && this.$emit("submit", payload);
    },
    emitError() {
      this.$emit("error");
    },
    inputEnable(input) {
      return this.rescheduling
        ? this.inputsEnabled.some((s) => s == input)
        : true;
    },
    getSupportStudios(search, sub) {
      if (search && search.length >= 3) {
        clearTimeout(this.autoCompleteTimer);
        this.isLoadingStudios = true;
        this.autoCompleteTimer = setTimeout(() => {
          _newAppointmentService
            .getSupportStudios(this.orchestratorId, sub.sub_event.value, search)
            .then((res) => {
              sub.search.studios = res;
            })
            .catch((error) => {
              this.$emit("warning", {
                type: "danger",
                message:
                  "Ocorreu um erro durante a listagem de estúdios. Por favor, entre em contato com o time de suporte.",
              });
              console.log(error);
            })
            .finally(() => (this.isLoadingStudios = false));
        }, 1500);
      }
    },
    handleNextStepHotKey(event) {
      if (event.key.toLowerCase() === "p" && event.shiftKey && event.altKey) {
        this.onClickSubmit();
      }
    },
  },
};
</script>

<style scoped>
.duration {
  max-width: 100px;
}
.c-footer {
  position: absolute;
  bottom: 0px;
}
</style>