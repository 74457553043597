<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="isLoading">
    <div class="mb-5" v-if="internalOptions">
      <div class="mb-1">Ordene os sub-evento(s):</div>
      <b-row>
        <b-col cols="6">
          <draggable
            :list="internalOptions"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
          >
            <b-list-group-item
              v-for="(item, index) in internalOptions"
              :key="index"
              tag="li"
              class="border mb-75"
            >
              <div class="d-flex">
                <small>#{{ index + 1 }} - </small>
                <div class="ml-25">
                  <b-card-text class="mb-0 font-weight-bold">
                    {{ item.label }}
                  </b-card-text>
                </div>
              </div>
            </b-list-group-item>
          </draggable>
        </b-col>
      </b-row>
    </div>
    <div class="c-footer d-block">
      <b-button
        :disabled="loading"
        :class="{ 'px-3': loading }"
        variant="info"
        @click="onClickSubmit"
      >
        <b-spinner v-if="loading" small />
        <span v-else>Próximo</span>
      </b-button>
    </div>
  </b-overlay>
</template>
<script>
import appConfig from "@core/app-config/useAppConfig";
import {
  BFormGroup,
  BInputGroup,
  BSpinner,
  BListGroupItem,
} from "bootstrap-vue";
import draggable from "vuedraggable";

export default {
  setup() {
    const { skin } = appConfig();
    return {
      skin,
    };
  },
  components: {
    BFormGroup,
    BInputGroup,
    BSpinner,
    BListGroupItem,
    draggable,
  },
  data() {
    return {
      isLoading: false,
      internalOptions: null,
      expectedPayloads: ["sub_events"],
      expectedOptions: ["sub_events"],
      inputsEnabled: [],
    };
  },
  props: {
    loading: Boolean,
    rescheduling: Boolean,
    payload: Object,
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.startComponent();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleNextStepHotKey);
  },
  methods: {
    startComponent() {
      // verifica se foi possível lidar com o payload e as options
      if (this.checkOptions() && this.checkPayload()) {
        this.handleOptions();
        this.handlePayload();
      } else {
        this.emitError();
      }
      document.addEventListener("keyup", this.handleNextStepHotKey);
    },
    checkPayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        // payload é opcional
        return this.expectedPayloads.every((key) => key in this.payload);
      }
      return true;
    },
    checkOptions() {
      return (
        this.$props.hasOwnProperty("options") &&
        this.options &&
        this.expectedOptions.every((key) => key in this.options) &&
        Array.isArray(this.options.sub_events) &&
        this.options.sub_events.length > 0 &&
        this.options.sub_events.every(
          (t) => t.hasOwnProperty("value") && t.hasOwnProperty("label")
        ) &&
        this.options.hasOwnProperty("can_change")
      );
    },
    handlePayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        let lstEvents = [];

        this.payload.sub_events.sort((a, b) => {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });

        this.payload.sub_events.forEach((sub) => {
          const subEvent = this.internalOptions.filter(
            (f) => f.value === sub.sub_event_id
          );
          if (subEvent.length > 0) {
            lstEvents.push(subEvent[0]);
          }
        });

        if (lstEvents.length == this.internalOptions.length) {
          this.internalOptions = [];
          this.internalOptions = lstEvents;
        } else {
          this.internalOptions.forEach((sub) => {
            const subEvent = lstEvents.filter((f) => f.value === sub.value);
            if (subEvent.length == 0) {
              lstEvents.push(sub);
            }
          });
          this.internalOptions = [];
          this.internalOptions = lstEvents;
        }
      }
    },
    handleOptions() {
      this.internalOptions = this.options.sub_events;
      this.inputsEnabled = this.options.can_change;
    },
    handleResponse() {
      let subEventsOrder = [];
      for (let isub = 0; isub < this.internalOptions.length; isub++) {
        subEventsOrder.push({
          sub_event_id: this.internalOptions[isub].value,
          order: isub,
        });
      }
      return {
        sub_events: subEventsOrder,
      };
    },
    onClickSubmit() {
      const payload = this.handleResponse();
      payload && this.$emit("submit", payload);
    },
    emitError() {
      this.$emit("error");
    },
    inputEnable(input) {
      return this.rescheduling
        ? this.inputsEnabled.some((s) => s == input)
        : true;
    },
    handleNextStepHotKey(event) {
      if (event.key.toLowerCase() === "p" && event.shiftKey && event.altKey) {
        this.onClickSubmit();
      }
    },
  },
};
</script>

<style scoped>
.duration {
  max-width: 100px;
}
.c-footer {
  position: absolute;
  bottom: 0px;
}
</style>