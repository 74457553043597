<template>
  <b-overlay :style="{ height: '100%' }" :variant="skin" :show="isLoading">
    <!-- Component Body -->
    <span>Produtos:</span>
    <div class="mt-1 mb-5 d-flex flex-wrap">
      <b-form-checkbox
        v-for="(prod, index) in products"
        class="custom-control-success mr-3 mb-50"
        :checked="prod.selected"
        v-model="prod.selected"
        name="check-button"
        :key="index"
        switch
        disabled
      >
        {{ prod.label }}
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon" />
        </span>
      </b-form-checkbox>
    </div>
    <div class="c-footer d-block">
      <b-button
        :disabled="loading"
        :class="{ 'px-3': loading }"
        variant="info"
        @click="onClickSubmit"
      >
        <b-spinner v-if="loading" small />
        <span v-else>Próximo</span>
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import appConfig from "@core/app-config/useAppConfig";
import { BSpinner } from "bootstrap-vue";

export default {
  setup() {
    const { skin } = appConfig();
    return {
      skin,
    };
  },
  components: {
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      products: [],
      expectedPayloads: ["selected_products"],
      expectedOptions: ["products"],
      inputsEnabled: [],
    };
  },
  props: {
    loading: Boolean,
    rescheduling: Boolean,
    payload: Object,
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.startComponent();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleNextStepHotKey);
  },
  methods: {
    startComponent() {
      // verifica se foi possível lidar com o payload e as options
      if (this.checkOptions() && this.checkPayload()) {
        this.handleOptions();
        this.handlePayload();
      } else {
        this.emitError();
      }
      document.addEventListener("keyup", this.handleNextStepHotKey);
    },
    checkPayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        // payload é opcional
        return (
          this.expectedPayloads.every((key) => key in this.payload) &&
          this.expectedPayloads.every((key) =>
            Array.isArray(this.payload[key])
          ) &&
          this.expectedPayloads.every((key) =>
            this.payload[key].every((v) => typeof v === "number")
          )
        );
      }
      return true;
    },
    checkOptions() {
      const has_correct_options =
        this.$props.hasOwnProperty("options") &&
        this.options &&
        this.expectedOptions.every((key) => key in this.options) &&
        this.options.hasOwnProperty("can_change");
      const has_correct_options_values = this.expectedOptions.every((key) => {
        return (
          Array.isArray(this.options[key]) &&
          this.options[key]?.length > 0 &&
          this.options[key].every(
            (t) => t.hasOwnProperty("value") && t.hasOwnProperty("label")
          )
        );
      });
      return has_correct_options && has_correct_options_values;
    },
    handlePayload() {
      if (this.$props.hasOwnProperty("payload") && this.payload) {
        const selected_projects = this.payload.selected_products;
        const updated_options = this.products.map((p) => ({
          ...p,
          selected: selected_projects.find((s) => String(p.value) === String(s))
            ? true
            : false,
        }));
        this.products = updated_options;
      }
    },
    handleOptions() {
      this.products = this.options.products;
      this.inputsEnabled = this.options.can_change;
    },
    handleResponse() {
      const selected_products = this.products.filter((p) => p.selected) ?? [];
      return {
        selected_products: selected_products?.map((p) => Number(p.value)),
      };
    },
    onClickSubmit() {
      const payload = this.handleResponse();
      payload && this.$emit("submit", payload);
    },
    emitError() {
      this.$emit("error");
    },
    inputEnable(input) {
      return this.rescheduling
        ? this.inputsEnabled.some((s) => s == input)
        : true;
    },
    handleNextStepHotKey(event) {
      if (event.key.toLowerCase() === "p" && event.shiftKey && event.altKey) {
        this.onClickSubmit();
      }
    },
  },
};
</script>

<style scoped>
.c-footer {
  position: absolute;
  bottom: 0px;
}
</style>